<template>
    <!-- Error page-->
    <div class="misc-wrapper qf-spotlight-section">

        <b-link class="brand-logo" href="https://cineself.com" target="_blank">
            <vuexy-logo />
        </b-link>

        <div class="p-2 p-sm-3" >
            <div id="alertNoProd" class="justify-content-center align-items-center text-center">
                <div class="w-100 text-center pb-2 pt-1">
                    <h2 class="mb-1">
                        🎬 Your audition invitation 🎬️
                    </h2>
                    <p class="mb-2">
                        You can find the details for the proposed role below
                    </p>
                </div>

                <b-row>
                    <b-col md="6" sm="12">
                        <b-card class="overflow-hidden p-2 text-left" no-body>
                            <transition name="fade" mode="out-in">
                                <b-row v-if="showProd" :key="'prod'" class="cs-minh-card">
                                    <b-col md="3">
                                        <b-card-img
                                            :src="curProdIllustration"
                                            alt="Image"
                                            class="rounded-0 mb-2"
                                        ></b-card-img>
                                        <span class="cs-see-more text-primary" @click="showRole()">
                                            <feather-icon
                                                class="mr-0"
                                                icon="ArrowLeftIcon"
                                                size="16"
                                            />
                                            Back to role details
                                        </span>
                                    </b-col>
                                    <b-col md="9">
                                        <b-row no-gutters>
                                            <!-- Basic info -->
                                            <b-col class="mb-2" md="12">
                                                <h4 class="mb-0">{{ relatedProduction.name }}</h4>
                                                <h6 class="mb-2">
                                                    ({{relatedProduction.productionType}})
                                                </h6>
                                                <p>{{ relatedProduction.synopsis }}</p>
                                                <hr class="qf-separator mb-0"/>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <h3 class="mb-1">Production Basics</h3>

                                                <div v-if="relatedProduction && relatedProduction.productionType" md="4" cols="6" class="my-05">
                                                    <h5>Production Type</h5>
                                                    <span>{{ relatedProduction.productionType }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.genres" md="4" cols="6" class="my-05">
                                                    <h5>Genres</h5>
                                                    <span>{{ relatedProduction.genres.join(', ') }}</span>
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <h3 class="mb-1">Services</h3>

                                                <div v-if="relatedProduction && relatedProduction.serviceCompany" md="4" cols="6" class="my-05">
                                                    <h5>Service Company</h5>
                                                    <span>{{ relatedProduction.serviceCompany }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.contactEmail" md="4" cols="6" class="my-05">
                                                    <h5>Contact E-Mail</h5>
                                                    <span>{{ relatedProduction.contactEmail }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.contactPhone" md="4" cols="6" class="my-05">
                                                    <h5>Contact Phone</h5>
                                                    <span>{{ relatedProduction.contactPhone }}</span>
                                                </div>
                                            </b-col>
                                            <hr class="qf-separator mb-2"/>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <h3 class="mb-1">Creative Team</h3>

                                                <div v-if="relatedProduction && relatedProduction.director" md="4" cols="6" class="my-05">
                                                    <h5>Director</h5>
                                                    <span>{{ relatedProduction.director }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.studio" md="4" cols="6" class="my-05">
                                                    <h5>Studio</h5>
                                                    <span>{{ relatedProduction.studio }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.producer" md="4" cols="6" class="my-05">
                                                    <h5>Producer</h5>
                                                    <span>{{ relatedProduction.producer }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.writer" md="4" cols="6" class="my-05">
                                                    <h5>Writer</h5>
                                                    <span>{{ relatedProduction.writer }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.castingDirector" md="4" cols="6" class="my-05">
                                                    <h5>Casting Director</h5>
                                                    <span>{{relatedProduction.castingDirector }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.executiveProducer" md="4" cols="6" class="my-05">
                                                    <h5>Executive Producer</h5>
                                                    <span>{{ relatedProduction.executiveProducer }}</span>
                                                </div>

                                                <div v-if="relatedProduction && relatedProduction.showrunner" md="4" cols="6" class="my-05">
                                                    <h5>Showrunner</h5>
                                                    <span>{{ relatedProduction.showrunner }}</span>
                                                </div>
                                            </b-col>

                                            <b-col>
                                                <h3 class="mb-1">Distribution</h3>
                                                <div v-if="relatedProduction && relatedProduction.platform" md="4" cols="6" class="my-05">
                                                    <h5>Platform</h5>
                                                    <span>{{ relatedProduction.platform }}</span>
                                                </div>
                                                <div v-if="relatedProduction && relatedProduction.studio" md="4" cols="6" class="my-05">
                                                    <h5>Production Studio</h5>
                                                    <span>{{ relatedProduction.studio }}</span>
                                                </div>
                                                <div v-if="relatedProduction && relatedProduction.distributor" md="4" cols="6" class="my-05">
                                                    <h5>Distributor</h5>
                                                    <span>{{ relatedProduction.distributor }}</span>
                                                </div>
                                                <div v-if="relatedProduction && relatedProduction.broadcaster" md="4" cols="6" class="my-05">
                                                    <h5>Broadcaster</h5>
                                                    <span>{{ relatedProduction.broadcaster }}</span>
                                                </div>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>

                                
                                <!---- ROLE DETAILS ---->
                                <b-row v-else :key="'role'" class="cs-minh-card">
                                    <b-col md="3">
                                        <b-card-img
                                            :src="curRoleIllustration"
                                            alt="Image"
                                            class="rounded-0"
                                        ></b-card-img>
                                    </b-col>
                                    <b-col md="9">
                                        <b-row no-gutters>
                                            <!-- Basic info -->
                                            <b-col class="mb-2" md="12">
                                                <h4 class="mb-0">{{ activeRole.attributes.name }}</h4>
                                                <h6 class="mb-0">
                                                    {{ roleTypeLabel(activeRole.attributes.roleType) }}
                                                </h6>
                                                <div class="mb-2"> in <span class="cs-see-more text-primary" @click="showProdDetails()">{{relatedProduction.name}}</span></div>
                                                <p>
                                                    <strong>AUDITION BY: </strong>{{
                                                        niceDate(activeRole.attributes.castBefore)
                                                    }}
                                                </p>
                                                <p>{{ activeRole.attributes.synopsis }}</p>
                                                <hr class="qf-separator mb-0"/>
                                            </b-col>

                                            <!-- Sides -->
                                            <b-col md="12" v-if="sides">
                                                <h4 class="mb-1">Sides:</h4>

                                                <div
                                                    v-for="oneSide in sides"
                                                    :key="oneSide.id"
                                                    class="d-flex justify-content-between align-items-center qf-sides mb-0"
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-0"
                                                            icon="FileIcon"
                                                            size="16"
                                                        />
                                                        <a :href="oneSide.attributes.attachment._url" target="_blank">
                                                            {{niceFileName(oneSide.attributes.attachment._name)}}
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr class="qf-separator mb-2"/>
                                            </b-col>

                                            <!-- Requirements -->
                                            <b-col md="12" v-if="hasAppearanceReqs || hasSkillReqs">
                                                <h4 class="mb-2">Requirements:</h4>
                                            </b-col>
                                        </b-row>
                                        <!-- Requirements -->
                                        <b-row>
                                            <b-col v-if="hasAppearanceReqs" md="12">
                                                <h5><em>Appearance</em></h5>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole && activeRole.attributes.ethnicity"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Ethnicity</h6>
                                                <span>{{
                                                        dictLabel(
                                                            activeRole.attributes.ethnicity,
                                                            "ethnicities"
                                                        )
                                                    }}</span>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole && activeRole.attributes.bodyType"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Body Type</h6>
                                                <span>{{
                                                        dictLabel(
                                                            activeRole.attributes.bodyType,
                                                            "bodyTypes"
                                                        )
                                                    }}</span>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole && activeRole.attributes.eyeColor"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Eye Color</h6>
                                                <span>{{
                                                        dictLabel(
                                                            activeRole.attributes.eyeColor,
                                                            "eyeColors"
                                                        )
                                                    }}</span>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole && activeRole.attributes.hairColor"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Hair Color</h6>
                                                <span>{{
                                                        dictLabel(
                                                            activeRole.attributes.hairColor,
                                                            "hairColors"
                                                        )
                                                    }}</span>
                                            </b-col>
                                            <b-col
                                                v-if=" activeRole && (activeRole.attributes.ageMin || activeRole.attributes.ageMax)"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Age Range</h6>
                                                <span>{{ activeRole.attributes.ageMin }} - {{ activeRole.attributes.ageMax }} yrs</span>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole && (activeRole.attributes.heightMax ||activeRole.attributes.heightMin)"
                                                class="pt-1"
                                                md="6"
                                            >
                                                <h6>Height range</h6>
                                                <span>{{ activeRole.attributes.heightMin }} - {{ activeRole.attributes.heightMax }} cm</span>
                                            </b-col>
                                            <b-col v-if="hasSkillReqs" class="pt-1" md="12">
                                                <h5>Skills and Languages</h5>
                                                <hr class="qf-separator mb-0"/>
                                            </b-col>
                                            <b-col
                                                v-if="activeRole.attributes.languages && activeRole.attributes.languages.length"
                                                class="pt-1"
                                                md="12"
                                            >
                                                <h6>Languages</h6>
                                                <b-badge
                                                    v-for="(lang, index) in activeRole.attributes
                              .languages"
                                                    :key="index"
                                                    class="mr-1"
                                                    variant="secondary"
                                                >{{ dictLabel(lang, "languages") }}
                                                </b-badge>
                                            </b-col>
                                            <b-col
                                                v-if="
                                                        activeRole.attributes.skills &&
                                                        activeRole.attributes.skills.length
                                                      "
                                                class="pt-1"
                                                md="12"
                                            >
                                                <h6>Skills</h6>
                                                <b-badge
                                                    v-for="(skill, index) in activeRole.attributes.skills"
                                                    :key="index"
                                                    class="mr-1"
                                                    variant="secondary"
                                                >{{ dictLabel(skill, "skills") }}
                                                </b-badge>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </transition>

                        </b-card>
                    </b-col>
                    <!-- END: Role Details -->

                    <!-- START: App Details -->
                    <b-col md="6" sm="12">
                        <!-- APP PROMO -->
                        <b-row>
                            <b-col md="6" sm="12" class="text-left">
                                <h4 class="pb-2">Apply for this role using the Cineself mobile app</h4>
                                <ol class="pl-1 pt-2">
                                    <li class="mb-1">Download the app</li>
                                    <li class="mb-1">Login with the credentials that you had just setup</li>
                                    <li class="mb-1">Check your "Inbox" tab</li>
                                    <li class="mb-1">Self-tape and upload your audition</li>
                                </ol>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-img
                                    fluid
                                    :src="appsImg"
                                    alt="Cineself Mobile Apps"
                                    class="cs-app-preview"
                                />
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col sm="12">
                                <b-link
                                    class="cs-app-store-link"
                                    href="https://apps.apple.com/bg/app/cineself/id1578879892"
                                    target="_blank"
                                >
                                    <b-img
                                        fluid
                                        :src="storeAppleImg"
                                        alt="Cineself in the Appstore"
                                    />
                                </b-link>
                                <b-link
                                    class="cs-app-store-link"
                                    href="https://play.google.com/store/apps/details?id=com.cineself.talent"
                                    target="_blank"
                                >
                                    <b-img
                                        fluid
                                        :src="storeGoogleImg"
                                        alt="Cineself in the Google Play store"
                                    />
                                </b-link>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- END: App Details -->
                </b-row>
            </div>


        </div>

    </div>
    <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
    BLink,
    //BButton,
    BImg,
    BCard,
    BRow,
    BCol,
    BCardImg,
    BBadge,

} from 'bootstrap-vue'

import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import Parse from "parse";

export default {
    components: {
        VuexyLogo,
        BLink,
        //BButton,
        BImg,
        BCard,
        BRow,
        BCol,
        BCardImg,
        BBadge,
    },
    data() {
        return {
            appsImg: require('@/assets/images/pages/cineself-app-screens.png'),
            storeAppleImg: require('@/assets/images/pages/appstore_button.png'),
            storeGoogleImg: require('@/assets/images/pages/googleplay_button.png'),
            roleId: this.$route.params.roleId,
            activeRole: {
                attributes : {}
            },
            dicts: {},
            showProd: false,
        }
    },
    mounted() {
        this.getRole(this.roleId);
        this.getSides(this.roleId);
        this.dicts = store.getters["dict/getRoleDicts"];
    },
    computed: {
        curRole() {
            return store.getters["app/currentRole"];
        },
        sides() {
            return store.getters["app/sides"];
        },
        relatedProduction(){
          if(this.activeRole.attributes.production){
            return this.activeRole.attributes.production.attributes;
          }else {
              return { attributes: {}};
          }
        },
        curRoleIllustration() {
            if (this.curRole) {
                return this.roleIllustration(this.curRole);
            } else {
                return this.defaultLogo("png");
            }
        },
        curProdIllustration() {
            if (this.relatedProduction  && this.relatedProduction.coverImage) {
                return this.relatedProduction.coverImage._url;
            } else {
                return this.defaultLogo("png");
            }
        },
        hasAppearanceReqs() {
            if (
                this.curRole &&
                (this.curRole.attributes.minHeight ||
                    this.curRole.attributes.maxHeight ||
                    this.curRole.attributes.ageMin ||
                    this.curRole.attributes.ageMax ||
                    this.curRole.attributes.hairColor ||
                    this.curRole.attributes.eyeColor ||
                    this.curRole.attributes.bodyType ||
                    this.curRole.attributes.ethnicity)
            ) {
                return true;
            } else {
                return false;
            }
        },
        hasSkillReqs() {
            if (
                this.curRole &&
                (
                    (this.curRole.attributes.languages && this.curRole.attributes.languages.length) ||
                    (this.curRole.attributes.skills && this.curRole.attributes.skills.length)
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        showProdDetails() {
            this.showProd = true;
        },
        showRole() {
            this.showProd = false;
        },
        roleIllustration(role) {
            let productionImg = this.defaultLogo("png");
            if (role.attributes.characterIllustration) {
                productionImg = role.attributes.characterIllustration._url;
            }
            return productionImg;
        },
        getRole(roleId) {
            let self = this;
            let RoleObj = Parse.Object.extend("ActingRole");
            let roleQuery = new Parse.Query(RoleObj);
            roleQuery.include("castingLocation");
            roleQuery.include("production");
            roleQuery
                .get(roleId)
                .then(function (role) {
                    store.commit("app/SET_ROLE", role);
                    self.activeRole = role;
                })
                .catch(function (err) {
                    console.log("Acting role fetch err", err.message);
                });
        },
        getSides(roleId) {
            let media = Parse.Object.extend("Media");
            let sidesQuery = new Parse.Query(media);

            store.commit('app/SET_SIDES', []);
            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: roleId,
            };

            sidesQuery.equalTo("relatedRole", rolePointer);
            sidesQuery.equalTo("mediaType", "side");

            sidesQuery
                .find()
                .then(function (results) {
                    store.commit("app/SET_SIDES", results);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
        },
        niceFileName(filename) {
            if (filename) {
                return filename.substring(33);
            } else {
                return "";
            }
        },
        roleTypeLabel(type) {
            let labels = {
                lead: "Lead",
                supporting: "Supporting",
                day_player: "Day Player",
            };

            if (labels[type]) {
                return "(" + labels[type] + ")";
            } else {
                return "";
            }
        },
        dictLabel(input, dict, short = false) {
            if (this.dicts[dict]) {
                let entry = this.dicts[dict].find((obj) => {
                    return obj.value === input;
                });
                if (entry) {
                    if (short) {
                        return entry.short;
                    } else {
                        return entry.text;
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
    },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.cs-minh-card {
    min-height: 350px;
}
.cs-see-more {
    color: #6c757d;
    cursor: pointer;
}

.cs-app-preview {
    max-height: 350px;
    height: auto;
    margin-bottom: 1rem;
}
.cs-app-store-link img{
    height: 50px;
    border: 1px solid;
}

#alertNoProd{
    height: 100%;
    min-height:100%;
}

.qf-spotlight-section {
    background: radial-gradient(circle, rgba(5,199,180,0.5) 0%, rgba(25,35,41,0.2) 35%, rgba(2,0,36,1) 90%);
}

#alertNoProd2:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/no-prod-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    /* background-position: 50% 0; */
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
}

.qf-sides {
    padding: 0.5rem 0;
}
.qf-sides + .qf-sides {
    border-top: 1px solid #3b4253;
}



</style>

<style scoped>
.brand-logo svg {
    width: auto;
}
</style>
